import React from "react";
import { Fade } from "react-awesome-reveal";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("Form successfully submitted!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    console.log("almost ready to send");
    const { name, email, subject, message } = data;

    try {
      const templateParams = {
        name: name,
        email: email,
        subject: subject,
        message: message,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      );

      reset(); // Reset form fields
      toastifySuccess(); // Show success toast
    } catch (e) {
      console.error(`Failed to send email. Error: ${e}`);
    }
  };

  return (
    <section id="contact">
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="ten columns">
            <h1>Contact us</h1>
          </div>
        </div>
      </Fade>

      <div className="row  display:none; ">
        <div className="eight columns">
          <form
            id="contact-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <fieldset>
              <div>
                <label htmlFor="name" style={{ fontFamily: "Montserrat" }}>
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="name"
                  name="name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Please enter your name",
                    },
                    maxLength: {
                      value: 30,
                      message: "Please use 30 characters or less",
                    },
                  })}
                />
                {errors.name && (
                  <span className="errorMessage">{errors.name.message}</span>
                )}
              </div>

              <div>
                <label htmlFor="email" style={{ fontFamily: "Montserrat" }}>
                  Email <span className="required">*</span>
                </label>
                <input
                  type="email"
                  defaultValue=""
                  size="35"
                  id="email"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                {errors.email && (
                  <span className="errorMessage">{errors.email.message}</span>
                )}
              </div>

              <div>
                <label htmlFor="subject" style={{ fontFamily: "Montserrat" }}>
                  Subject
                </label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="subject"
                  name="subject"
                  {...register("subject", {
                    required: false,
                    maxLength: {
                      value: 75,
                      message: "Subject cannot exceed 75 characters",
                    },
                  })}
                />
                {errors.subject && (
                  <span className="errorMessage">{errors.subject.message}</span>
                )}
              </div>

              <div>
                <label htmlFor="message" style={{ fontFamily: "Montserrat" }}>
                  Message <span className="required">*</span>
                </label>
                <textarea
                  cols="50"
                  rows="15"
                  id="message"
                  name="message"
                  {...register("message", {
                    required: true,
                  })}
                ></textarea>
                {errors.message && (
                  <span className="errorMessage">Please enter a message</span>
                )}
                <div>
                  <div
                    className="ContactFormInput"
                    style={{
                      width: "100%",
                      height: "60px",
                      textAlign: "center",
                    }}
                  >
                    <button
                      className="submit-btn"
                      type="submit"
                      style={{
                        width: "50%",
                        fontSize: "1em",
                        border: "1px solid #ccc",
                        background: "transparent",
                        borderRadius: "0px",
                        padding: "5px 20px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      width: "100%",
                      height: "60px",
                      textAlign: "center",
                    }}
                  >
                    By submitting you accept our{" "}
                    <a
                      href="/AIATELLA_Privacy_Policy.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>
            </fieldset>
          </form>
          <ToastContainer />
        </div>
        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>AIATELLA</h4>
            <p>info(at)aiatella.com</p>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default Contact;
