import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/partners/" + projects.image + ".webp";
      let projectImage2 = "images/partners/" + projects.image + ".png";

      return (
        <div id="portfolio-part" style={{textAlign: "center"}} key={projects.title}>
          <a target="_blank" href={projects.url} rel="noreferrer">
            <picture>
              <source srcSet={projectImage} type="image/webp" />
              <img
                alt={projects.title}
                style={{ maxHeight: "100px", width: "auto" }}
                src={projectImage2}
              />
            </picture>
            <p style={{ textAlign: "center" }}>{projects.title}</p>
          </a>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              <AliceCarousel
                autoPlay
                autoPlayInterval={500}
                animationDuration={4000}
                infinite
                items={projects}
                responsive={{
                  1200: { items: 5 },
                  1024: { items: 3 },
                  600: { items: 2 },
                  0: { items: 1 },
                }}
                controlsStrategy="responsive"
                disableButtonsControls
                disableDotsControls
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
