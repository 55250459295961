import React, { Component } from "react";
import Counter from "./Counter";
import SavedCounter from "./SavedCounter";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="about">
        <div className="row">
          <div style={{ width: "100%", textAlign: "left" }}>
            <Counter />
            <h3 style={{ padding: "20px 0px" }}>
              <SavedCounter />
            </h3>
            <a href="#contact" style={{ textDecoration: "none" }}>
              <button
                style={{
                  borderRadius: 0,
                  backgroundColor: "#d10d10",
                  float: "left",
                }}
              >
                Contact us.
              </button>
            </a>
          </div>
          <p style={{ paddingTop: "100px", width: "100%" }}>
            Every year, cardiovascular diseases claim over 20 million lives
            worldwide. Many of these deaths could be prevented with early
            detection. Yet, the global healthcare system is overwhelmed, limited
            by human capacity for detailed analysis.
            <br />
            <br />
            The challenge is clear: traditional methods of image analysis can't
            keep pace with the need.
            <br />
            That's where AIATELLA steps in. Our groundbreaking AI-powered image
            analysis solution transforms the landscape of cardiovascular
            diagnostics. By automating manual measurements of vasculature in a
            quantified way and using historical data, we drastically cut down on
            the time, cost, and level of expertise needed, significantly
            expanding the capacity for critical imaging.
            <br />
          </p>
          <p>
            Our goal? To revolutionize cardiovascular disease management by
            making it more efficient, precise, and affordable, ensuring early
            detection and treatment are within everyone's reach.
          </p>
        </div>
      </section>
    );
  }
}

export default About;
