import React from "react";
const Team = ({ data }) => {
  if (!data) return null;

  const members = data.members.map((member, index) => {
    let memberImage = "images/team/" + member.image + ".webp";
    let memberImage2 = "images/team/" + member.image + ".png";
    return (
      <div key={index} className="team-columns team-item">
        <div className="team-item-wrap">
          <a href={member.link}>
            <picture>
              <source srcSet={memberImage} type="image/webp" />
              <img alt={member.title} src={memberImage2} />
            </picture>
          </a>
          <h4 style={{ textAlign: "center" }}>
            <br />
            {member.name}
          </h4>
          <div style={{ textAlign: "center" }}>{member.title}</div>
        </div>
      </div>
    );
  });

  return (
    <section id="team">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Team</h1>
          <div id="team-wrapper" className="team-grid">
            {members}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
