import React, { Component } from "react";
import { Link } from "react-router-dom";

class Blog extends Component {
  render() {
    if (!this.props.data) return null;

    const posts = this.props.data.map(function (post) {
      let postImage = "images/blog/" + post.image + ".webp";
      let postImage2 = "images/blog/" + post.image + ".png";
      return (
        <div
          id="blog-post"
          className="blog"
          style={{ textAlign: "center", marginBottom: "40px" }}
          key={post.id}
        >
          <Link to={{ pathname: `/blog/${post.id}` }}>
            <div id="blog-part" style={{ display: "flex" }} key={post.title}>
              <picture
                id="big-post-image"
                style={{ width: "100%", maxWidth: "200px" }}
              >
                <source srcSet={postImage} type="image/webp" />
                <img
                  alt={post.title}
                  src={postImage2}
                  style={{ width: "100%", maxWidth: "200px" }}
                />
              </picture>
              <div style={{ textAlign: "left", padding: "10px" }}>
                <picture
                  id="small-post-image"
                  style={{ height: "100px", marginBottom: "20px" }}
                >
                  <source srcSet={postImage} type="image/webp" />
                  <img
                    alt={post.title}
                    src={postImage2}
                    style={{ height: "100px" }}
                  />
                </picture>
                <h2 style={{ paddingTop: "0px" }}>{post.title}</h2>
                <p style={{ marginBottom: "0px" }}>{post.textShort}</p>
                <p style={{ fontWeight: "bold" }}>{post.date}</p>
              </div>
            </div>
          </Link>
        </div>
      );
    });

    return (
      <section id="blog">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Blog</h1>
            <div id="blog-wrapper">{posts}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog;
