import React, { Component } from "react";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      minHeight: "500px", // Default minHeight
    };
    this.containerRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    if (this.containerRef.current) {
      const containerBounds = this.containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const containerTop = containerBounds.top;
      const containerBottom = containerBounds.bottom;

      let opacity = 0;
      if (containerBottom >= 0 && containerTop <= windowHeight) {
        const visibleHeight =
          Math.min(containerBottom, windowHeight) - Math.max(containerTop, 0);
        const totalHeight = containerBounds.height;
        opacity = visibleHeight / totalHeight;
      }

      this.setState({ opacity });
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll(); // To set the initial opacity based on the page load position
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const windowHeight = window.innerHeight;
    const calculatedMinHeight = `${Math.max(300, windowHeight * 0.5)}px`; // For example, 50% of the window height, but not less than 300px
    this.setState({ minHeight: calculatedMinHeight });
  }

  render() {
    const { opacity, minHeight } = this.state;

    return (
      <section id="product" style={{ backgroundColor: "rgba(238,238,238)" }}>
        <div
          ref={this.containerRef}
          style={{
            textAlign: "center",
            width: "100%",
            margin: "0 auto",
            padding: "5% 0",
            paddingTop: "0%",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "1040px",
              margin: "0 auto",
              overflow: "hidden",
              minHeight: minHeight, // Use state to dynamically adjust minHeight
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/images/segment2.png"
              alt="Original"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
              }}
            />
            <img
              src="/images/segment2.png"
              alt="Segmented anatomical area"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                transition: "opacity 0.5s linear",
                opacity: opacity,
              }}
            />
          </div>
          <div id="mockupRow">
            <div id="mockup">
              <img src="/images/aiatella_mockup_transparent.png" alt="Mockup" />
            </div>

            <div id="texts">
              <br />
              <h2>Increased accuracy, throughput, and turnover.</h2>
              <br />
              <p style={{ color: "#000" }}>
                AIATELLA's solution improves the speed, accuracy and precision
                of diagnostics, delivering measurements and disease-progression
                analysis in seconds instead of hours. 
              </p>
              <p style={{ color: "#000" }}>
                Contact us to learn more about our technology and for research
                collaborations.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Product;
