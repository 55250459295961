import React from "react";
import "primeicons/primeicons.css";

var speed = 1.14;
function Counter() {
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    counter !== -1 && setTimeout(() => setCounter(counter + speed), 2000);
  }, [counter]);

  return (
    <div style={{ paddingTop: "40px" }} className="Counter">
      <h2 style={{fontSize:"36px"}}>
        <span style={{ fontSize: "54px", color: "red" }}>
          {Math.round(counter)}{" "}
        </span>{" "}
        People have died from cardiovascular disease since you opened our page.
      </h2>
    </div>
  );
}

export default Counter;
