import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import BlogPage from "./BlogPage";
import BlogPost from "./BlogPost";
import TeamPage from "./TeamPage";

const Main = (props) => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home data={props} />}></Route>
        <Route path="blog/:postId" element={<BlogPost data={props} />}></Route>
        <Route path="/blog" element={<BlogPage data={props} />}>
          {" "}
        </Route>
        <Route path="/team" element={<TeamPage data={props} />}></Route>
        <Route path="*">"404 Not Found"</Route>
      </Routes>
    </div>
  );
};

export default Main;
