import React from "react";
import "primeicons/primeicons.css";

var speed = 0.912;
function SavedCounter() {
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    counter !== -1 && setTimeout(() => setCounter(counter + speed), 2000);
  }, [counter]);

  return (
    <div className="SavedCounter">
      <h3>
        <span style={{ fontSize: "30px", color: "red" }}>
          {Math.round(counter)}{" "}
        </span>{" "}
        Could have been saved with early detection and treatment
      </h3>
    </div>
  );
}

export default SavedCounter;
