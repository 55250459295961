import React, { Component } from "react";

class Nav extends Component {
  render() {
    return (
      <div>
                <img id="bigimage" src="logo512.png" alt="logo"></img>

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#nav-wrap-closed" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                AIATELLA
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                THE CHALLENGE
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#product">
                SOLUTION
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#contact">
                CONTACT
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="/team">
                TEAM
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="/blog">
                BLOG
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Nav;
