import React, { Component } from "react";
import Blog from "./Blog";
import BlogNav from "./BlogNav";
import $ from "jquery";

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null,
    };
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
        console.log(data);
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
      },
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <div>
        <BlogNav />
        <div className="blog">
          <Blog data={this.props.data.data.blogPosts} />
        </div>
      </div>
    );
  }
}

export default BlogPost;
