import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import About from "./About";
import Resume from "./Resume";
import Contact from "./Contact";
import Portfolio from "./Portfolio";
import Nav from "./Nav";
import Product from "./Product";

const Home = (props) => {
  console.log(props);

  return (
    <div>
      <Nav />
      <Header data={props.data.data.main} />
      <About data={props.data.data.main} />
      <Resume data={props.data.data.resume} />
      <Portfolio data={props.data.data.portfolio} />
      <Product />
      <Contact data={props.data.data.main} />
      <Footer data={props.data.data.main} />
    </div>
  );
};

export default Home;
