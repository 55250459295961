import React, { Component } from 'react';
import Team from './Team';
import Advisors from './Advisors';
import BlogNav from './BlogNav';
class TeamPage extends Component {
    render() { 
        return (
        <div>
        <BlogNav />
        <Team data={this.props.data.data.team}/> <Advisors data={this.props.data.data.advisors}/>);
        </div>
        )
    }
}
 
export default TeamPage;