import React, { Component } from "react";
import BlogNav from "./BlogNav";
class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null,
    };
  }

  componentDidMount() {
    const postId = new URL(window.location.href).pathname.split("blog/")[1]; // Extracting post ID from URL
    this.getResumeData(postId);
  }

  getResumeData(postId) {
    fetch("../resumeData.json")
      .then((res) => res.json())
      .then((data) => {
        // Assuming your data structure supports lookup by id directly or through filtering
        const post = data.blogPosts.find((post) => post.id === postId); // Find the post by id
        if (post) {
          this.setState({ post });
        }
      });
  }

  render() {
    return (
      <section id="blog-post">
        <BlogNav />
        <div className="row">
          <div id="blog-post-page" style={{ textAlign: "left" }}>
            {this.state.post && (
              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <picture
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "contain",
                  }}
                >
                  <source
                    srcSet={"/images/blog/" + this.state.post.image + ".webp"}
                    type="image/webp"
                  />
                  <img
                    alt={this.state.post.title}
                    src={"/images/blog/" + this.state.post.image + ".png"}
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      objectFit: "contain",
                    }}
                  />
                </picture>
                <h1>{this.state.post.title}</h1>
                <p
                  style={{
                    textAlign: "left",
                    padding: "10px",
                    whiteSpace: "pre-line",
                  }}
                >
                  {this.state.post.text}
                </p>
                <div id="author-block">
                  <img
                    src={this.state.post.authorImage}
                    alt={this.state.post.author}
                    style={{
                      height: "100%",
                      maxHeight: "100px",
                      borderRadius: "50%",
                    }}
                  />
                  <p>by: {this.state.post.author}</p>
                  <p>{this.state.post.date}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default BlogPost;
