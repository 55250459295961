import React, { Component } from "react";

class Resume extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="resume">
        <div id="resumeblock">
          <h2
            style={{
              borderLeft: "4px solid #d10101",
              borderRight: "4px solid #d10101",
            }}
          >
            A world with 80% less cardiovascular deaths.
          </h2>
          <p>
            Enabling true preventative screening, precision treatment and
            prevention of cardiovascular diseases.
          </p>
        </div>
      </section>
    );
  }
}

export default Resume;
