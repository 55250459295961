import React, { Component } from "react";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    return (
      <header id="home">
        <div className="row banner">
          <video
            autoPlay
            loop
            muted
            playsInline
            id="metaball"
            class="background-video"
          >
            <source src="/videos/Metaball.mp4" type="video/mp4"></source>
          </video>
          <div className="banner-text">
            <h1 id="bigtitle" className="responsive-headline">
              {name}
            </h1>
            <h3 id="bigdescription">Automating Medical Image Analysis</h3>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
